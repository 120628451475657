.educational {
    margin-bottom: 4rem;

    &__title {
      text-transform: none;
      font: 600 2.8rem "Inter";
      margin-bottom: 3.4rem;
    }
  
    &__links {
        font-weight: 400;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        a { 
            z-index: 2;
            &:hover {
                text-decoration: underline;
            }
        }
    }

}
  