.menu {
  &_students {
    display: none;
  }

  &__fix {
    &_students {
      height: auto;
    }
  }
}
