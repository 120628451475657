.menu {
  position: fixed;
  bottom: calc(100% - 22rem);
  right: calc(50% - 58rem);
  z-index: 2000;

  &__fix {
    background: #fff;
    padding: 4rem 2rem 2rem 2rem;
    border: 0.1rem solid #e2e5e8;
    opacity: 0;
    border-radius: 2rem;
    width: 20rem;
    height: 24.4rem;
    position: absolute;
    right: 0rem;
    //display: none;
    transition: 0.5s;
  }

  &__btn {
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #e2e5e8;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    background: #fff;
    z-index: 100;

    &:before {
      content: "";
      position: absolute;
      top: 1.5rem;
      left: 0;
      right: 0;
      width: 1.9rem;
      height: 0.3rem;
      background: #e2e5e8;
      margin: auto;
      transform: rotate(0deg);
      border-radius: 0.1rem;
      transition: 0.5s;
      animation-duration: 0.5s;
      animation-name: hmb-btn-out;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 1.5rem;
      right: 0;
      width: 1.9rem;
      height: 0.3rem;
      background: #e2e5e8;
      margin: auto;
      transform: rotate(0deg);
      border-radius: 0.1rem;
      transition: 0.5s;
      animation-duration: 0.5s;
      animation-name: hmb-btn-out;
    }
  }

  &__item {
    padding: 1.6rem 0 1.6rem 0;
    border-bottom: 0.1rem solid #a0a8b1;
    line-height: 1;
    color: #6e7175;
    font-size: 1.2rem;
    font-weight: 400;

    &_active {
      color: #1c6e3d;
      font-weight: 600;
    }
    &:last-child {
      border-bottom: 0;
    }
  }

  &__link {
    &:hover {
      color: #1c6e3d;
    }
  }
}

.active-menu-fix {
  opacity: 1;
  transition: 0.5s;
}

.active-menu-fix-none {
  display: none;
}

.active-btn-fix {
  &:before {
    transform: rotate(-45deg);
    transition: 0.5s;
    top: 0;
    bottom: 0;
    animation-duration: 0.5s;
    animation-name: hmb-btn;
  }

  &:after {
    transform: rotate(-135deg);
    transition: 0.5s;
    top: 0;
    bottom: 0;
    animation-duration: 0.5s;
    animation-name: hmb-btn;
  }
}

@keyframes hmb-btn {
  from {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  to {
    bottom: 0;
    top: 0;
  }
}

@keyframes hmb-btn-out {
  from {
    bottom: 0;
    top: 0;
  }

  to {
    top: 1.5rem;
    bottom: 1.5rem;
  }
}

.active-menu-fixed {
  color: #1c6e3d;
  font-weight: 600;
}
