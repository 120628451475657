.cooperations {
  padding-top: 8rem;
  margin-bottom: 12rem;

  &__title {
    margin-bottom: 6.3rem;
  }

  &__subtitle {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    letter-spacing: 0.1rem;
    margin-bottom: 4rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: calc(97% / 3);
    /* min-height: 100%; */
    border-radius: 2rem;
    border: 0.1rem solid #cfd3d8;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin-bottom: 2rem;

    &:last-child {
      background: url("/img/nav/partners/blocks/form-partners/form-heptagon.png")
        #fdce4c left / 90% no-repeat;
      margin-bottom: 0;
      justify-content: space-between;
      padding: 2.4rem;
    }

    &:nth-child(7) {
      margin-bottom: 0;
    }

    &:nth-child(8) {
      margin-bottom: 0;
    }
  }

  &__mobile {
    display: none;
  }

  &__circle {
    background: #fee9b1;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &_white {
      background: #fff;
    }
  }

  &__icon {
    width: 2.6rem;
    height: 2.6rem;
  }

  &__desc {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.33;
  }

  &__button {
    width: 100%;
    height: 6rem;
    background: #fff;

    &_mobile {
      height: 100%;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background: url("/img/nav/partners/blocks/cooperation/09.svg") center /
          90% no-repeat;
        width: 2.3rem;
        top: 0;
        left: 2.2rem;
        bottom: 0;
      }
    }
  }
}
