.partners {
  margin-bottom: 19rem;

  &__head {
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    line-height: 1.2;
  }

  &__list {
    display: flex;
    margin-bottom: 17rem;
  }

  &__item {
    background: center center / 48% auto no-repeat;
    width: 28rem;
    height: 14rem;
    border: 0.1rem solid #cfd3d8;
    border-radius: 1rem;
    margin-right: 2rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__desc {
    font-family: "PF Din Text Comp Pro Medium";
    font-size: 9.6rem;
    letter-spacing: -0.1rem;
    line-height: 1;
  }
}

.arrow {
  &_mobile {
    display: none;
  }

  &__left {
    background-color: #e4e7eb;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    position: relative;
    margin-right: 1.6rem;
    transition: .3s;

    &::before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 13.1504L2 7.15043L8 1.15039' stroke='%23A0A2A6' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E")
        center/30% no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover {
      background-color: #C9EDD5;
      transition: .3s;
    }
  }

  &__right {
    background-color: #e4e7eb;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    position: relative;
    transition: .3s;

    &::before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 13.1504L2 7.15043L8 1.15039' stroke='%23A0A2A6' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E")
        center/30% no-repeat;
      position: absolute;
      transform: rotate(-180deg);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &:hover {
      background-color: #C9EDD5;
      transition: .3s;
    }
  }
}

.swiper {
  height: 20rem;

  @include tablets {
    height: 15rem;
  }
}
