.form {
  background: $green;
  max-width: 115.6rem;
  // height: 23.3rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  padding: 3.4rem 4.8rem 5rem 4.8rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  min-height: 23rem;

  &__title {
    font-size: 2.8rem;
    color: #fff;
    margin-bottom: 2.4rem;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 1.4rem;
  }
  // изменил block на item
  &__item {
    margin-right: 2rem;
    width: 33rem;
  }

  &__input {
    width: 100%;
    height: 5.5rem;
    border-radius: 0.5rem;
    border: none;
    padding: 1.6rem;
  }

  &__text {
    color: #fff;
    font-size: 1.2rem;

    &-tablets {
      display: none;
    }
  }
  &-success{
    border-radius: 2rem;
    background: $green;
    padding: 3.4rem 4.8rem 5rem 4.8rem;
		transition: 0.3s;
    position: absolute;
    left: -9999px;
    opacity: 0;
		overflow: hidden;
		max-height: 0;
    .form-sended ~ & {
      	position: static;
      	opacity: 1;
        max-height: unset;
		}


		&-inner {
    }

    &__text{
      font-size: 4.6rem;
      line-height: 110%;
    }
  }
  .send-success {
    display: none;
  }
}

.form-sended {
  position: absolute;
  left: -9999px;
  opacity: 0;
  overflow: hidden;
  max-height: 0;
}


label.error {
  display: none !important;
}

.form__item .error {
  border: 0.1rem solid #f00;
  outline: none;
}