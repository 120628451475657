.spa {
  padding-top: 7rem;
  background: #f5f7fa;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 3.5rem;
  }

  &__subtitle {
    font-size: 2.8rem;
    margin-bottom: 6rem;
    width: 75%;
  }

  &__left {
    width: 66%;
  }

  &__right {
    width: 25%;
  }

  &__wish {
    background: url("/img/nav/students/spa/blocks/spa/spa-bg.png") center right -2rem /
        40% no-repeat,
      #fff;
    border-radius: 3rem;
    padding: 4.6rem 8rem 5rem 5rem;
    margin-bottom: 6rem;

    &-one {
      margin-bottom: 1.7rem;
      font-size: 2rem;
    }

    &-two {
      margin-bottom: 3rem;
      font-size: 2rem;
      width: 95%;
    }

    &-three {
      margin-bottom: 2.9rem;
      font-weight: 400;
      font-size: 1.8rem;
    }

    &-for {
      font-weight: 400;
      font-size: 1.8rem;
    }
  }
}
//
.accordion {
  margin-bottom: 2rem;
  border-radius: 2rem;
  background: #fff;

  &-list {
    margin-bottom: 12rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-size: 2rem;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 4rem;
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
    background: #f5f7fa;
    transition: transform 0.3s ease-out;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 50%;
      height: 0.1rem;
      background-color: #000;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.1rem;
      height: 50%;
      background-color: #000;
      transform: translate(-50%, -50%);
    }
  }

  &__control {
    width: 100%;
    background-color: transparent;
    cursor: pointer;
  }

  &__content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 4rem;
    will-change: max-height;
    transition: all 0.3s ease-out;
    // box-sizing: content-box;

    &-title {
      font-size: 1.6rem;
      position: relative;

      &:before {
        content: "";
        background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' fill='%23C4C4C4'/%3E%3C/svg%3E%0A")
          center center/0.6rem no-repeat;
        position: absolute;
        width: 0.6rem;
        top: 0;
        bottom: 0;
        left: -2.1rem;
      }
    }

    &-text {
      font-size: 1.6rem;
      font-weight: 400;

      &_bottom {
        margin-bottom: 0.8rem;
      }
    }

    &-item {
      margin-left: 2.1rem;
      margin-bottom: 1.6rem;
    }

    &-subitem {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 1rem;
      list-style: inside;
      margin-left: 1rem;
    }
  }
}

.open .accordion__icon {
  transform: rotate(45deg);
}

.open .accordion__content {
  opacity: 1;
  padding: 0 4rem 4rem 4rem;
}
