.hello {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding-top: 8rem;

  &::before {
    content: "";
    background: url(/img/hello/hello.png) bottom right/contain no-repeat;
    position: absolute;
    top: -1rem;
    // left: 0;
    right: 0;
    bottom: 23rem;
    z-index: -1;
    width: 58%;
  }


  &__title {
    font-size: 2.8rem;
    margin-bottom: 3rem;
    white-space: nowrap;
  }

  &__subtitle {
    font-size: 9.6rem;
    width: 42%;
    text-transform: uppercase;
    font-family: "PF Din Text Comp Pro Medium";
    line-height: 1;
    letter-spacing: -0.1rem;
    white-space: nowrap;
    margin-bottom: 6rem;

    &_mob {
      display: none;
    }
  }
}
