.event {
    padding-top: 8rem;
    margin-bottom: 12rem;

    &__title {
        margin-bottom: 6.3rem;
    }

    &__description {
        padding-left: 9.2rem;
        border-left: 0.3rem solid #e91e63;
        font-size: 2.8rem;
        font-weight: 400;
        line-height: 1.7;
        margin-bottom: 12rem;
    }

    &__content {

        &-text {
            display: flex;
            flex-direction: column;
            gap: 3rem 0;
            margin-bottom: 4rem;

            P {
                font-weight: 400;
                font-size: 16px;
                margin: 0;
            }
        }

        &-photo {
            margin-bottom: 4rem;
        }
    }
}