.head {
  display: flex;
  flex-direction: column;

  &__for-visually {
    display: flex;
    background: #e4e7eb;
    height: 2.4rem;
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    /* right: 0; */
    /* bottom: 0; */
    /* padding: 0 14.2rem; */
    align-items: center;
    margin-bottom: 1.4rem;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }

  &__vis-eye {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: url(/img/head/vis.svg) center left/10% no-repeat;
      top: 0;
      left: -2.5rem;
      right: 0;
      bottom: 0;
    }
  }

  &__contacts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.3rem;
    align-items: center;
  }

  &__nav {
    min-height: 4rem;
    display: flex;
    border-bottom: 1px solid #e4e7eb;
    align-items: center;
    border-top: 1px solid #e4e7eb;
  }

  &__nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    a {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a + a {
      margin-left: 0.5rem;
    }
    a:last-child {display: none;}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__time {
    white-space: nowrap;
    line-height: 1;
    position: relative;
    margin-right: 4rem;
    padding-left: 2.3rem;

    &::before {
      content: "";
      position: absolute;
      background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 1.09091C3.28878 1.09091 1.09091 3.28878 1.09091 6C1.09091 8.71122 3.28878 10.9091 6 10.9091C8.71122 10.9091 10.9091 8.71122 10.9091 6C10.9091 3.28878 8.71122 1.09091 6 1.09091ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 2.18182C6.30125 2.18182 6.54545 2.42603 6.54545 2.72727V5.66289L8.42575 6.60304C8.6952 6.73776 8.80441 7.0654 8.66969 7.33484C8.53497 7.60429 8.20733 7.7135 7.93788 7.57878L5.75607 6.48787C5.57127 6.39547 5.45455 6.2066 5.45455 6V2.72727C5.45455 2.42603 5.69875 2.18182 6 2.18182Z' fill='%23FBB800'/%3E%3C/svg%3E%0A")
        center left / 1.2rem no-repeat;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    margin-right: 2.6rem;

    &_active {
      color: #1c6e3d;
      font-weight: 600;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    &:hover {
      color: #1c6e3d;
      transition: 0.3s;
    }
  }

  &__search {
    z-index: 100;
  }
}
.contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 3.4rem;

  &__phone {
    font-style: normal;
    margin-right: 4rem;
    position: relative;
    font-size: 1.4rem;
    line-height: 1;
    padding-left: 1.8rem;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      content: "";
      background: url(/img/head/tel.svg) center center / 1.1rem no-repeat;
      position: absolute;
      width: 1.1rem;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
}

.logo {
  max-width: 30rem;
  &__icon {
    &-tablets {
      display: none;
    }

    &-mobile {
      display: none;
    }
  }
}

.hamburger {
  width: 2.4rem;
  height: 2.4rem;
  flex-direction: column;
  cursor: pointer;
  background-color: #fbb800;
  border-radius: 0.5rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.6rem;
  display: none;

  &__plank {
    display: block;
    background: #fff;
    width: 1.2rem;
    height: 0.2rem;
    border-radius: 10px;
  }
}

.search {
  position: absolute;
  top: 0;
  left: 99999px;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: .2s;
  &__input {
    border: none;
    outline: none;
    width: 100%;
    min-height: 100%;
  }
}

.active-search {
  left: 0;
}