

.basic {
  background: #f5f7fa;
  padding-bottom: 13rem;

  // h3 {
  //   font: 500 3rem "PF Din Text Comp Pro Medium", sans-serif;
  //   text-transform: uppercase;
  //   margin: 5rem 0 2rem;
  // }

  // h4 {
  //   font: 600 1.4rem "Inter", sans-serif;
  //   color: #212121;
  //   line-height: 1.42;
  //   text-transform: none;
  // }

  // p {
  //   font-weight: 400;
  //   margin-bottom: 0.5rem;
  //   a {text-decoration: underline;}
  // }

  // ul {
  //   li + li {margin-top: 0.5rem;}
  // }

//   .scrolled-wrapper {
//     position: relative;
//     overflow: hidden;
//     cursor: move;
//     margin: 3.6rem 0;
//   }

//   .scrolled-wrapper .scrolled-wrapper_scrolled {
//     outline: 0.2rem solid #eceff7;
//   }

//   .scrolled_h {
//     max-width: 100%;
//     overflow:hidden;
//     cursor: move;
//   }

//   .scrolled_h > table {
//     max-width: 9999em;
//   }

//   .scrolled_h__top-scroll {
//     position: absolute;
//     width: 100%;
//     height: 2rem;
//     overflow-x: auto;
//     display: none;
//   }

//   .fake-scroll {
//     height: 0.1rem;
//   }

//   table {
//     font-size: 1.2rem;
//     font-weight: 400;
//     max-width: max-content;
//     width: 100%;
//     border-collapse: collapse;
//     display: block;
//     // background: rgba(236, 239, 247, .3);
//   }

//   .table caption {
//     padding: 1.25rem 1.75rem;
//     background: #9496b0;
//     color: #fff;
//     font-size: 24px;
//     text-align: left;
// }

//   table td {
//     border: 0.1rem solid #e4e7eb;
//     padding: 1rem;
//     background: rgba(236, 239, 247, .3);
//   }

//   table tbody th,
//   table thead th {
//     padding: 1.4rem 2rem;
//     background: #e4e7eb;
//     font-weight: 400;
//   }


  &__body {
    display: flex;
    justify-content: space-between;
    &-wide {
      width: 100%;
    }
  }

  &__left {
    width: 100%;
  }

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    line-height: 1.14;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }

  &__list {
    display: flex;
    margin-bottom: 4rem;
  }

  &__item {
    margin-right: 9.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__top {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    line-height: 1.28;
  }

  &__bottom {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.36;
  }

  &__desc {
    font-weight: 400;
    line-height: 1.57;
    margin-bottom: 4.8rem;
  }
}

