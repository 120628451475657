.why {
  padding-bottom: 12rem;

  &__title {
    font: 4.8rem "PF Din Text Comp Pro";
    margin-bottom: 5rem;
    letter-spacing: 0.1rem;
    line-height: 1;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 7.5rem 0;
  }

  &__item {
    position: relative;
    flex: 0 0 40%;
    padding-left: 8.7rem;

    &:before {
      position: absolute;
      top: 0; 
      left: 0;
      content: '';
      width: 71px;
      height: 71px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
    }

    &_1:before {background-image: url(/img/nav/students/employment/blocks/why/icon1.svg);}
    &_2:before {background-image: url(/img/nav/students/employment/blocks/why/icon2.svg);}
    &_3:before {background-image: url(/img/nav/students/employment/blocks/why/icon3.svg);}
    &_4:before {background-image: url(/img/nav/students/employment/blocks/why/icon4.svg);}
    &_5:before {background-image: url(/img/nav/students/employment/blocks/why/icon5.svg);}

    &-title {
      font-weight: 600;
      font-size: 2rem;
      line-height: 121%;
      color: $yellowLogo;
      margin-bottom: 1.5rem;
    }

    &-text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 121%;
    }
  }

}