.head {
  &__nav-fix {
    height: 4rem;
    justify-content: space-between;
    padding: 3rem 1rem;
    border: none;
    align-items: center;
    position: fixed;
    z-index: 100;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0rem 1rem 2rem 1rem #18222e17;
    transform: translateY(-100%);
    transition: all 0.3s ease;
  }
}

.out {
  transform: translateY(0);
}
