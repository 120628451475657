.top_page_menu{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 6rem;
  a {
    display: block;
    padding: 0.6rem 1.1rem;
    border: 0.1rem solid #32A05D;
    margin-right: 1rem;
    margin-bottom: 1rem;
    color: #32A05D;
    font-size: 1.3rem;
    transition: 0.2s;
    &.active,
    &:hover {
      background-color:#32A05D;
      color: #fff;
      text-decoration: none;
    }
  }
}