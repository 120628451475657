.acting {
  &_about {
    margin-bottom: 12rem;
  }

  &__top_about {
    margin-bottom: 5.3rem;
    display: flex;
    justify-content: space-between;
  }

  &__employers_about {
    margin-bottom: 12rem;
  }

  &__title_about {
    margin-bottom: 0;
  }

  &__item {
    &_about {
      border: 0.1rem solid #cfd3d8;
      border-radius: 2rem;
      display: flex;
      width: 37.2rem;
      padding: 2.4rem;
      align-items: flex-start;
      margin-bottom: 2.2rem;
    }

    &-text_about {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.33;
      margin-right: 2.4rem;
    }

    &-img_about {
      background: #fee9b1;
      min-width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-icon_about {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}
