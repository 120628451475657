/**********/
/* Common */
@import 'common/_variables';
@import 'common/_base';

/* popup */
@import 'popup/_style';

/*************/
/* Blocks */
@import 'banner/_style';
@import 'neuronet/_style';
@import 'head/_style';
@import 'foot/_style';
@import 'hello/_style';
@import 'edu/_style';
@import 'directions/_style';
@import 'about/_style';
@import 'partners/_style';
@import 'links/_style';
@import 'news/_style';
@import 'form/_style';

@import 'button/_style';

@import 'menu-mob/_style';

@import 'fix-menu/_style';

@import 'breadcrumbs/_style';

@import 'head-nav-fix/_style';

@import 'nav/program/blocks/program-edu/_style';
@import 'nav/program/blocks/form/_style';

@import 'nav/cards/entrepreneurship/blocks/faculties/_style';
@import 'nav/cards/entrepreneurship/blocks/disciplines/_style';
@import 'nav/cards/entrepreneurship/blocks/become/_style';
@import 'nav/cards/entrepreneurship/blocks/exams/_style';
@import 'nav/cards/entrepreneurship/blocks/documents/_style';

@import 'nav/cards/finance/blocks/faculties/_style';

@import 'nav/cards/hotel/blocks/faculties/_style';

@import 'nav/cards/economy/blocks/faculties/_style';

@import 'nav/cards/personnel-management/blocks/faculties/_style';

@import 'nav/cards/municipal-government/blocks/faculties/_style';

@import 'nav/cards/project-management/blocks/faculties/_style';

@import 'nav/cards/civil-law/blocks/faculties/_style';

@import 'nav/cards/banking/blocks/faculties/_style';

@import 'nav/cards/criminal-law/blocks/faculties/_style';

@import 'nav/cards/trading-business/blocks/faculties/_style';

@import 'nav/cards/hotel-service/blocks/faculties/_style';

@import 'nav/cards/information-systems/blocks/faculties/_style';

@import 'nav/cards/design/blocks/faculties/_style';

@import 'nav/cards/economics-accounting/blocks/faculties/_style';

@import 'nav/cards/commerce-by-industry/blocks/faculties/_style';

@import 'nav/cards/law-organization/blocks/faculties/_style';

@import 'nav/cards/logistics/blocks/faculties/_style';

@import 'nav/cards/healthcare/blocks/faculties/_style';

@import 'nav/cards/sports-management/blocks/faculties/_style';

@import 'nav/cards/marketing/blocks/faculties/_style';

@import 'nav/cards/taxation/blocks/faculties/_style';


@import 'nav/contacts/blocks/contacts/_style';
@import 'nav/partners/blocks/college/_style';
@import 'nav/partners/blocks/univer/_style';
@import 'nav/partners/blocks/educational/_style';
@import 'nav/partners/blocks/acting/_style';
@import 'nav/partners/blocks/agents/_style';
@import 'nav/partners/blocks/cooperation/_style';
@import 'nav/partners/blocks/form-partners/_style';
@import 'nav/about/blocks/about-academy/_style';
@import 'nav/about/blocks/history/_style';
@import 'nav/about/blocks/founder/_style';
@import 'nav/about/blocks/acting-about/_style';
@import 'nav/applicants/blocks/entrant/_style';
@import 'nav/applicants/blocks/reception/_style';
@import 'nav/applicants/blocks/admission/_style';
@import 'nav/applicants/blocks/proceed/_style';
@import 'nav/applicants/blocks/dormitory/_style';

@import 'fix-menu-students/_style';

@import 'nav/students/spa/blocks/spa/_style';

@import 'nav/students/timetable/blocks/timetable/_style';

@import 'nav/students/menu-students/blocks/menu-stud/_style';

@import 'nav/students/services/blocks/paid/_style';

@import 'nav/students/council/blocks/council/_style';

@import 'nav/students/library/blocks/library/_style';

@import 'nav/students/dormitory/blocks/dormitory/_style';

@import 'nav/students/employment/blocks/employment/_style';

@import 'nav/students/employment/blocks/why/_style';

@import 'nav/students/employment/blocks/vacancy/_style';

@import 'nav/students/employment/blocks/information/_style';

@import 'nav/sveden/paid_edu/blocks/intelligence/_style';

@import 'nav/sveden/paid_edu/blocks/form/_style';

@import 'nav/sveden/common/blocks/top_page_menu/_style';

@import 'nav/sveden/common/blocks/basic/_style';

@import 'nav/sveden/common/blocks/form/_style';

@import 'nav/sveden/common/blocks/simplebar/_style';

@import 'nav/menu-basic/_style';

@import 'fix-menu-intelligence/_style';

@import 'nav/sveden/document/blocks/docs/_style';

@import 'nav/sveden/document/blocks/form/_style';

@import 'nav/edu-foreigner/blocks/ru/edu-foreigner/_style';

@import 'nav/edu-foreigner/blocks/ru/foreigner-desc/_style';

@import 'nav/edu-foreigner/blocks/ru/advantages/_style';

@import 'nav/edu-foreigner/blocks/ru/questions/_style';

@import 'nav/sveden/eduStandarts/blocks/standards/_style';

@import 'nav/science/blocks/events/_style';
@import 'nav/science/blocks/event/_style';

/*************/