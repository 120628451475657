.library {
  background: #f5f7fa;
  padding-bottom: 12rem;

  @include phones {
    padding-bottom: 6rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 68%;
    // flex: 2 0 20%;
    // margin-right: 9.8rem;
  }

  &__title {
    margin-bottom: 3.2rem;
  }

  &__subtitle {
    font-size: 2.8rem;
    margin-bottom: 4rem;
    width: 75%;
  }

  &__tasks {
    padding: 4.8rem 10.6rem 7.8rem 4.8rem;
    background: url("/img/nav/students/library/blocks/library/lib-bg.svg")
        center right 2rem / 40% no-repeat,
      #fff;
    border-radius: 3rem;
  }

  &__tasks-title {
    font-size: 2rem;
    margin-bottom: 1.8rem;
  }

  &__tasks-subtitle {
    font-size: 2rem;
    margin-bottom: 2.9rem;
  }

  &__tasks-desc {
    font-weight: 400;
    font-size: 1.8rem;
    // margin-bottom: 3.2rem;
    margin-bottom: 2.8rem;
  }

  &__right {
    width: 25%;
    // flex: 1;
  }
}
