.faculties-logistics {
  position: relative;
  height: 55rem;
  z-index: 1;

  &::before {
    content: "";
    background: url(/img/nav/cards/logistics/blocks/faculties/fac-img.png) bottom
      right/41% no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
