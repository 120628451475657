.program-edu {
  margin-bottom: 13rem;

  &__title {
    text-transform: uppercase;
    font-family: "PF Din Text Comp Pro Medium";
    font-size: 6.4rem;
    margin: 8rem 0 3.5rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    width: 75%;
  }

  &__right-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.8rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    display: none;
    flex-direction: column;
    width: 27.4rem;
    min-height: 21rem;
    // justify-content: space-between;
    margin-bottom: 6rem;
    &:nth-child(-n + 6) {
      display: flex;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__pic {
    margin-bottom: 0.8rem;
  }

  &__img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 1rem;
  }

  &__info {
    width: 6rem;
    height: 2rem;
    font-size: 1.2rem;
    background-color: #c9edd5;
    color: #32a05d;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8rem;
  }

  &__desc {
    font-size: 1.6rem;
  }

  &__more {
    display: flex;
    justify-content: center;
  }
}

.form-program {
  margin-bottom: 4.8rem;
  position: relative;

  &__elem {
    margin-bottom: 0.8rem;
  }

  &_mobile {
    position: absolute;
    top: 14rem;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    margin-top: 1.9rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.2rem;

    &_mobile {
      position: relative;
      padding: 1.4rem 1.6rem 1.4rem 1.6rem;
      background: #fff;
      margin-bottom: 0;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 1.6rem;
        right: 0.8rem;
        top: 0;
        bottom: 0;
        background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6L8 11L3 6' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
          center right / 1.6rem no-repeat;
        opacity: 0.6;

        -webkit-transition: all 0.27s ease-in-out;
        -o-transition: all 0.27s ease-in-out;
        transition: all 0.27s ease-in-out;

        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(-90deg);

        @include phones {
          right: 1.6rem;
        }
      }

      &.rotate {
        position: relative;

        &:before {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__check {
    &_mobile {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s;
      
      &.active-acc {
        overflow: hidden;
        max-height: 28.8rem;
      }
    }
  }

  &__link {
    font-weight: 400;
    color: #43c375;
  }
}

.switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 4.8rem;

  &_mobile {
    padding: 1.4rem 1.6rem 1.4rem 1.6rem;
    background: #fff;
    margin-bottom: 0.8rem;
  }

  &__title {
    margin-right: 2rem;
    font-size: 1.3rem;
    font-weight: 500;

    &_mobile {
      margin-right: 0;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  &__visible {
    width: 3.8rem;
    height: 2.2rem;
    background-color: #cfd3d8;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    // transition: 0.5s;

    &::after {
      content: "";
      width: 1.8rem;
      height: 1.8rem;
      background-color: #e8f5f7;
      position: absolute;
      border-radius: 70px;
      top: 0.2rem;
      right: 1.8rem;
      // transition: 0.5s;
    }
  }

  &__btn {
    display: none;
  }

  &__btn:checked + &__visible:after {
    left: calc(50% - 1.9rem);
    transform: translateX(100%);
  }

  &__btn:checked + &__visible {
    background-color: #5acb86;
  }
}

.radio {
  display: flex;
  cursor: pointer;
  margin-bottom: 0.8rem;
  position: relative;

  &_more {
    display: none;

    &:nth-child(-n + 6) {
      display: flex;
    }
  }

  &_mobile {
    display: flex;
    cursor: pointer;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 4.8rem;
    background: #fff;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 0.1rem solid #cfd3d8;
    padding: 0 1.6rem 0 1.6rem;
  }

  &__title {
    font-weight: 400;
  }

  &__elem {
    display: none;
  }

  &__visible {
    width: 1.6rem;
    height: 1.6rem;
    position: relative;
    border-radius: 0.3rem;
    border: 0.1rem solid #a0a8b1;
    margin-right: 0.8rem;

    &::before {
      content: "";
      background: url("/img/nav/program/blocks/program-edu/checkmark.svg")
        center center / 70% no-repeat;
      width: 1.6rem;
      height: 1.6rem;
      background-color: #43c375;
      border-radius: 0.3rem;
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__elem:checked ~ &__visible::before {
    display: block;
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;

  // @include phones {
  //     width: 70%;
  // }

  &__item {
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__elem {
    cursor: pointer;
  }

  &__checkbox {
    display: none;
  }
}

.tag {
  padding: 0.6rem 2.8rem 0.6rem 1.6rem;
  background-color: #43c375;
  max-width: 14rem;
  min-height: 3.2rem;
  border-radius: 3rem;
  color: #fff;
  position: relative;

  &::before {
    content: "";
    background: url("/img/nav/program/blocks/program-edu/X.svg") center right
      no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0.8rem;
    bottom: 0;
  }
}

.select {
  width: 17.6rem;
  display: block;
  position: relative;
}

.new-select {
  position: relative;
  border: 1px solid #ced4da;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  border-radius: 0.4rem;

  &__list {
    position: absolute;
    top: 45px;
    left: 0;
    border: 1px solid #ced4da;
    cursor: pointer;
    width: 100%;
    z-index: 2;
    background: #fff;
    user-select: none;
    border-radius: 0.4rem;
  }

  &__list.on {
    display: block;
  }

  &__item span {
    display: block;
    padding: 1rem 1.5rem;
  }

  &__item span:hover {
    color: #12b223;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    right: 0.8rem;
    top: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6L8 11L3 6' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
      center right / 1.6rem no-repeat;
    opacity: 0.6;

    -webkit-transition: all 0.27s ease-in-out;
    -o-transition: all 0.27s ease-in-out;
    transition: all 0.27s ease-in-out;

    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  &.on:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.filter {
  display: none;
  width: 9rem;
  height: 3.2rem;
  position: relative;

  &_mobile {
    background-color: #fff;
    width: 100%;
    height: 5.2rem;
    margin-bottom: 1.5rem;

    &::after {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 3.5L3.5 12.5' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 12.5L3.5 3.5' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        center right / 1.8rem no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 1.6rem;
      bottom: 0;
    }
  }

  &:before {
    content: "";
    background: url("/img/nav/program/blocks/program-edu/filter.svg") center
      left / 1.8rem no-repeat;
    position: absolute;
    top: 0;
    left: -0.8rem;
    right: 0;
    bottom: 0;
  }

  &_mobile::before {
    left: 1.6rem;
  }
}

.mobile {
  position: fixed;
  display: none;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: #f5f7fa;
  top: 0;
  z-index: 99;
  border-left: 0.4rem solid #43c375;
  overflow: auto;
  scroll-snap-type: y;
}
