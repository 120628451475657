.simplebar-track.simplebar-horizontal {
  height: 0.6rem !important;
  background-color: #e4e7eb;
  border-radius: 1rem;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  top: 0 !important;
  height: 0.6rem !important;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  background-color: #43c375;
  opacity: 1;
}
