.dormitory {
  &_students {
    margin-bottom: 0;
    background: #f5f7fa;
    padding-bottom: 12rem;
  }

  &__body {
    &_students {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.8rem;
    }
  }
  &__desc {
    &_students {
      margin-bottom: 0;
      width: 66%;
    }
  }

  &__title {
    &_students {
      font-family: "PF Din Text Comp Pro Medium";
      font-size: 6.4rem;
      letter-spacing: 0;
      margin-bottom: 3.2rem;
    }
  }

  &__subtitle {
    &_students {
      font-size: 2.8rem;
      width: 100%;
      margin-bottom: 2.8rem;
    }
  }

  &__list {
    &_students {
      justify-content: space-between;
    }
  }

  &__item {
    &_students {
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(95% / 3);
      margin-right: 0;
    }
  }

  &__item-title {
    &_students {
      margin-bottom: 2rem;
      white-space: unset;
    }
  }

  &__icon {
    &_students {
      height: 16rem;
    }
  }

  &__img {
    &_students {
      object-fit: contain;
    }
  }

  &__menu {
    &_students {
      width: 25%;
    }
  }
}
