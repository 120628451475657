.univer {
  &__title {
    text-transform: none;
    font: 600 2.8rem "Inter";
    margin-bottom: 3.4rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: 24%;
    // height: 21.4rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 4.3rem;
    z-index: 2;
  }

  &__icon {
    // width: 27.4rem;
    height: 16rem;
    // border: 0.1rem solid #CFD3D8;
    // border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 0.8rem;
    border: 0.1rem solid #cfd3d8;
    border-radius: 1rem;
    padding: 2.2rem;
  }

  &__img {
    height: 100%;
    object-fit: contain;
    width: 100%;
    border-radius: 1rem;
  }

  &__desc {
    font-weight: 400;
  }
}
