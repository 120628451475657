.history {
  margin-bottom: 12rem;

  &__desc {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8rem;

    &-left {
      width: 42%;
      font-size: 2rem;
      display: flex;
      align-items: center;
    }

    &-right {
      font-weight: 400;
      width: 49%;
      font-size: 1.8rem;
      line-height: 1.47;

      &_green {
        background: rgba(50, 205, 119, 0.26);
      }
    }
  }

  &-academy {
    position: relative;
    padding: 7.2rem 8.5rem 6rem 10.2rem;
    margin-bottom: 15.6rem;
    display: flex;

    &:before {
      content: "";
      position: absolute;
      background: url("/img/nav/about/blocks/history/history-bg.jpeg") center
        center/cover no-repeat;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 2rem;
      z-index: -1;
      box-shadow: 0rem 8rem 5rem -4rem #cbcbcb;
    }

    &:after {
      content: "";
      position: absolute;
      background: url(/img/nav/about/blocks/history/history-bg-2.png) center
        center/cover no-repeat;
      bottom: -5rem;
      width: 31rem;
      height: 39rem;
      z-index: -1;
      transform: rotate(-6deg);
      left: 4.2rem;
    }

    &__title {
      font-family: "PF Din Text Comp Pro";
      font-size: 4.8rem;
      margin-right: 11rem;
      letter-spacing: 0.1rem;
      line-height: 1.16;
    }

    &__desc {
      // flex: 3;
    }

    &__subtitle {
      font-size: 2.8rem;
      margin-bottom: 4rem;
    }

    &__text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.4;

      &-desc {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
          width: 95%;
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8rem;

    &-left {
      font-weight: 400;
      line-height: 1.6;
      font-size: 2.8rem;
      width: 40%;
    }

    &-right {
      width: 49%;

      &-text {
        font-weight: 400;
        width: 90%;
        font-size: 1.6rem;
        line-height: 1.36;

        @include phones {
          width: 100%;
        }

        &_green {
          background: rgba(50, 205, 119, 0.26);
        }

        &-bold {
          font-size: 2rem;
          margin-bottom: 2rem;
          width: 85%;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10rem;
  }

  &__item {
    border: 0.1rem solid #cfd3d8;
    border-radius: 2rem;
    display: flex;
    width: 49%;
    padding: 2.4rem 1.6rem 2.4rem 2.4rem;
    align-items: flex-start;
    margin-bottom: 2.2rem;

    &-img {
      background: #fee9b1;
      min-width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2.4rem;
    }

    &-icon {
      width: 2.6rem;
      height: 2.6rem;
    }

    &-text {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.33;
    }
  }
}
