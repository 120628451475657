.backdrop{
    // &::before{
    //     background: #000;
    //     position: fixed; 
    //     left: 0;
    //     top: 0;
    //     width: 100%; 
    //     height: 100%;
    //     opacity: 0.5;
    //     z-index: 9999;
    // }
    position: fixed;
	z-index: 999;
    top: 0;
    left: 0;
	transition: 0.3s opacity ;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

	&_hidden {
        visibility: hidden;
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

    &__p {
        opacity: .5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
    }
}

.popup-banner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	height: 90vh;
	width: 80vw;
	opacity: 1;
	padding: 7.7rem 0 3rem;
    overflow: hidden;
    font-family: "PF Din Text Comp Pro";
	background: #fff;
    &::before{
        content: '';
        
    }
	.container{
        height: 65rem;
    }
	&__button{
        position: absolute;
        top: 3rem;
        right: 5.2rem;
		width: fit-content;
		cursor: pointer;
        &-close{}
	}
    &__block{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__top{
        position: relative;
        z-index: 2;
        margin-bottom: 10rem;
        &-title{
            text-transform: uppercase;
            text-align: center;
            font-size: 9.8rem;
            color: #212121;
            font-weight: 600;
            span{
                color: #00A258;
            }
            br{
                display: none;
            }
        }
    }
    &__center{
        position: relative;
        display: flex;
        height: 30rem;
        width: 52.2rem;
        
        &-heart{
            position: absolute;
            top: -8.5rem;
            left: 11rem;
            width: 16rem;
            transform: rotate(26deg);
            z-index: 1;
        }
        &-mbHeart{
            display: none;
        }
        &-hand{
            position: absolute;
            top: -11.5rem;
            left: 27rem;
            transform: translate(-50%, 50%);
            z-index: 1;
            width: 34.5rem;

        }
        &-date{
            position: absolute;
            top: 0;
            left: 0;
            font: 7.7rem "PF Din Text Comp Pro Medium", sans-serif;
            z-index: 0;
            border-radius: 1.6rem;
            padding: 1rem 4.3rem;
            color: #212121;
            width: 31.5rem;
            text-transform: uppercase;
            height: fit-content;
            background: #FDCE4C;
            transform: rotate(-8deg);
        }
        &-time{
            position: absolute;
            top: 4.2rem;
            right: 2.4rem;
            font: 7.7rem "PF Din Text Comp Pro Medium", sans-serif;
            z-index: 1;
            border-radius: 1.6rem;
            text-transform: uppercase;
            padding: 1rem 4.3rem;
            color: #fff;
            background: #00A258;
            height: fit-content;
            transform: rotate(6deg);
        }
    }
    &__bottom{
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .9rem;
        &-text{
            color: #212121;
            font-family: "Inter";
            font-weight: 600;
            line-height: 120%;
            text-align: center;
            font-size: 2.1rem;
        }
        &-button{
            font-size: 3.3rem;
            color: #fff;
            padding: 1.6rem 4.8rem;
            font-family: "Inter";
            font-weight: 600;
            background: #B200CF;
            border-radius: .8rem;
            border: .2rem solid #B200CF;
            box-shadow: .7rem .7rem 0px 0px #9900B2;
            &:hover{
                background: white;
                transition: .3s;
                color: #B200CF;
            }
        }
    }
    &__img{
        &-book{
            position: absolute;
            top: 10.5rem;
            left: -7.8rem;
            width: 25.2rem;
            transform: rotate(-69deg);
        }
        &-girl{
            position: absolute;
            bottom: 0;
            width: 37.1rem;
            left: 3.2rem;
        }
        &-man{
            position: absolute;
            bottom: -25.2rem;
            right: -2rem;
            width: 44.8rem;
            z-index: 3;
        }
        &-pencil{
            transform: rotate(36deg);
            position: absolute;
            top: 9.5rem;
            width: 15.6rem;
            right: 5.5rem;
        }
    }
}
