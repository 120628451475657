.become {
  margin-bottom: 12rem;

  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12rem;
  }

  &__left {
    width: 100%;
  }

  &__right {
    display: flex;
  }

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    margin-bottom: 3.2rem;
  }

  &__text {
    font-size: 2rem;
    font-weight: 400;
    white-space: pre-line;
    width: 70%;
  }

  &__list {
    // column-count: 2;
    // column-gap: 2.1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    background: #f5f7fa;
    width: 49%;
    min-height: 13.2rem;
    border-radius: 2rem;
    padding: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      min-height: 16rem;
    }

    // &:nth-child(3n + 2) {
    //     margin-bottom: 0;
    // }
  }

  &__position {
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }

  &__money {
    color: #6e7175;
    font-weight: 400;
  }

  &__banner {
    max-width: 115.6rem;
    background-color: #e91e63;
    border-radius: 2rem;
    padding: 5rem 0 5rem 6rem;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      background-image: url(/img/nav/cards/entrepreneurship/blocks/become/become.png);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 80rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &::after {
      content: "";
      background-image: url(/img/nav/cards/entrepreneurship/blocks/become/banner-img.png);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: -10rem;
      right: 0;
      bottom: -10rem;
      left: 0;
      z-index: -1;
    }
  }

  &__banner-title {
    font-size: 3.6rem;
    color: #fff;
    margin-bottom: 1.6rem;

    &_phones {
      display: none;
    }
  }

  &__banner-desc {
    font-size: 2rem;
    color: #fff;
    width: 40%;
    margin-bottom: 4rem;
    font-weight: 500;
  }

  &__banner-button {
    height: 5.2rem;
    font-size: 2rem;
  }
}

.orange {
  color: #fa9800;
  font-size: 2rem;
}
