.events {
    padding-top: 8rem;
    margin-bottom: 12rem;

    &__title {
        margin-bottom: 6.3rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 8.3rem;
    }

    &__item {
        background: center top / contain no-repeat;
        width: calc(97% / 3);
        display: none;
        margin-bottom: 2rem;

        &:nth-child(-n + 6) {
            display: flex;
        }

        &-link {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    &__img {
        overflow: hidden;
        height: 17.2rem;
        border-radius: 2rem 2rem 0 0;
    }
    
    &__icon {
        height: 100%;
        object-fit: cover;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
    }
    
    &__info {
        flex-grow: 1;
        border: 0.1rem solid #cfd3d8;
        border-radius: 0 0 2rem 2rem;
        padding: 2rem 1.6rem 6rem 2rem;
    }
    
    &__date {
        margin-bottom: 1.2rem;
        font-size: 1.4rem;
        font-weight: 400;
    }
    
    &__desc {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.37;
    }
    
    &__more {
        display: flex;
        margin-bottom: 7rem;
        justify-content: center;
    }
}