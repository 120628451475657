.founder {
  background: #ededed;
  border-radius: 2rem;
  padding: 9.3rem 0 11.4rem;
  margin-bottom: 12rem;

  &__top {
    display: flex;
  }

  &__left {
    display: flex;
    flex-direction: column;
    margin-right: 7.7rem;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 58%;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 4.8rem;
    font-family: "PF Din Text Comp Pro";
    letter-spacing: 0.1rem;
    margin-bottom: 3.3rem;
  }

  &__photo {
    border: 1rem solid #fff;
    width: 31.6rem;
    height: 29.2rem;
    border-radius: 3rem;
    box-shadow: 0rem 4rem 4rem -1rem #cbcbcb;
    background: url("/img/nav/about/blocks/founder/founder.png") center center /
      cover no-repeat;
    z-index: 1;

    &_mobile {
      display: none;
    }
  }

  &__subtitle {
    font-size: 2.8rem;
    margin-bottom: 2.3rem;
  }

  &__desc-text {
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.5;

    &-italic {
      font-style: italic;
      font-weight: 400;
      margin-bottom: 3.7rem;
      line-height: 1.6;
    }
  }

  &__bottom {
    border: 0.2rem solid #fff;
    border-radius: 2rem;
    position: relative;
    margin-top: -2rem;

    &:before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='116' height='117' viewBox='0 0 116 117' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_484_2510)'%3E%3Cpath d='M36.836 17H52.94L44.156 57.992H51.842V93.494H20V58.724L36.836 17ZM79.658 17H95.762L86.978 57.992H94.664V93.494H62.822V58.724L79.658 17Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_484_2510' x='0' y='0' width='115.762' height='116.494' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='3'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.0951077 0 0 0 0 0.132467 0 0 0 0 0.179167 0 0 0 0.09 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_484_2510'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_484_2510' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
        center / 100% no-repeat;
      position: absolute;
      width: 7.7rem;
      height: 7.7rem;
      top: -4rem;
      right: 0;
      bottom: 0;
      left: -2rem;
      z-index: 1;
    }

    &:after {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='116' height='117' viewBox='0 0 116 117' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_484_2510)'%3E%3Cpath d='M36.836 17H52.94L44.156 57.992H51.842V93.494H20V58.724L36.836 17ZM79.658 17H95.762L86.978 57.992H94.664V93.494H62.822V58.724L79.658 17Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_484_2510' x='0' y='0' width='115.762' height='116.494' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='3'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.0951077 0 0 0 0 0.132467 0 0 0 0 0.179167 0 0 0 0.09 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_484_2510'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_484_2510' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
        center / 100% no-repeat;
      position: absolute;
      width: 7.7rem;
      height: 7.7rem;
      /* top: -4rem; */
      right: -2rem;
      bottom: -4rem;
      /* left: 4px; */
      transform: rotate(180deg);
    }
  }

  &__feedback {
    padding: 4rem 15.3rem;

    &-text {
      font-weight: 400;
      margin-bottom: 2.3rem;
      font-size: 1.6rem;

      &_down {
        width: 90%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-top {
        font-weight: 400;
        font-size: 2rem;
        margin-bottom: 1.9rem;
        width: 80%;
      }
    }
  }
}
