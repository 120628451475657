.breadcrumbs {
  margin-bottom: 5rem;
  padding-top: 1.4rem;
  line-height: 1.1;

  &__item {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__link {
    font-size: 1.2rem;
    color: #6e7175;
    border-bottom: 0.1rem solid #604fc9;
    font-weight: 400;
    margin-right: 1rem;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 0.6rem;
      right: -0.8rem;
      top: 0;
      bottom: -0.3rem;
      background: url("data:image/svg+xml,%3Csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L3 3L1 1' stroke='%23A0A2A6' stroke-linecap='round'/%3E%3C/svg%3E%0A")
        center right/ 0.6rem no-repeat;
    }
  }

  &__desc {
    font-size: 1.2rem;
    font-weight: 400;
    color: #a0a2a6;
  }
}
