.contacts-academy {
  margin-bottom: 12rem;

  &__body {
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    margin-bottom: 12rem;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7.3rem;
  }

  &__head {
    width: 50%;
  }

  &__map {
    width: 50%;
  }

  &__title {
    margin-bottom: 4rem;
  }

  &__adress {
    margin-bottom: 1.6rem;
  }

  &__mail {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__street {
    font-size: 2.8rem;
    margin-bottom: 1.6rem;
  }

  &__time {
    background: #f5f7fa;
    width: 17.5rem;
    height: 3.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    margin-bottom: 1.6rem;
  }

  &__way {
    width: 90%;

    &_mobile {
      display: none;
    }
  }

  &__support-title {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__tel-time {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__phone {
    font-size: 2.8rem;
    font-style: normal;
    margin-right: 1.6rem;
  }

  &__time-support {
    background: #f5f7fa;
    width: 17.5rem;
    height: 3.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
  }

  &__right {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 6rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__mail-title {
    text-transform: uppercase;
    color: #424344;
  }

  &__adress-mail {
    font-size: 2.8rem;
  }
}

.form-contacts {
  margin-bottom: 11rem;
}
