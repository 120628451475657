.council {
  background: #f5f7fa;
  padding-bottom: 12rem;

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 65%;
  }

  &__right {
    width: 25%;
  }

  &__title {
    margin-bottom: 3.2rem;
  }

  &__subtitle {
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }

  &__goals {
    display: flex;
    margin-bottom: 6rem;
  }

  &__line {
    border-left: 0.4rem solid #e91e63;
    margin-right: 9.2rem;
    border-radius: 1rem;
  }

  &__desc {
    font-weight: 400;
    width: 60%;

    &-title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    &-text {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tasks {
    background: url("/img/nav/students/council/blocks/council/council-bg.svg")
        center right 2rem / 40% no-repeat,
      #fff;
    // background-color: #fff;
    padding: 4.8rem 3.2rem 4.8rem 4.8rem;
    border-radius: 3rem;

    &-title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    &-subtitle {
      font-size: 2rem;
      margin-bottom: 3.2rem;
    }

    &-desc {
      width: 85%;
    }

    &-text {
      font-weight: 400;
    }
  }
}
