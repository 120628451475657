.documents {
  margin-bottom: 12rem;

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    margin-bottom: 4rem;
  }

  &__list {
    display: flex;
    margin-bottom: 12rem;
  }

  &__item {
    width: 37.2rem;
    height: 36rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    font-size: 1.8rem;
  }

  &__desc {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
