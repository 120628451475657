.proceed {
  margin-bottom: 15rem;

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    margin-bottom: 7rem;
    letter-spacing: 0.1rem;
  }

  &__steps-table {
    margin-bottom: 15rem;
  }

  &__steps {
    width: 75%;
    border: 0.1rem solid #cfd3d8;
    border-radius: 2rem;
    padding: 3.3rem 3.6rem 4.3rem 3.6rem;
    margin-bottom: 2rem;
  }

  &__step-desc {
    margin-bottom: 2.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__step {
    padding: 0.9rem 1.2rem;
    background: #c9edd5;
    width: 6.7rem;
    border-radius: 1rem;
    color: #1c6e3d;
    text-transform: uppercase;
    line-height: 1;
    white-space: nowrap;
    margin-bottom: 2.8rem;
  }

  &__step-title {
    font-size: 2rem;
    margin-bottom: 1.2rem;
  }

  &__step-subtitle {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }

  &__step-item {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
    list-style: inside;

    &_num {
      list-style: inside auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #80d69f;
    flex: 0 0 4rem;
    margin-right: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__desc {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__step-note {
    font-weight: 400;
  }

  &__note {
    font-weight: 400;
    font-size: 1.6rem;
  }
}
