.entrant {
  position: relative;
  margin-bottom: 10rem;

  &:before {
    content: "";
    background: #f5f7fa;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 85%;
    z-index: -1;
  }

  &__body {
    display: flex;
  }

  &__title {
    margin-bottom: 5rem;
  }

  &__subtitle {
    font-size: 2.8rem;
    margin-bottom: 3rem;
    line-height: 1.3;
  }

  &__desc {
    font-size: 2rem;
    font-weight: 400;
    word-break: break-all;
  }

  &__left {
    width: 40%;
    margin-bottom: 6rem;
    padding-top: 7rem;
  }

  &__right {
    position: relative;
    width: 40%;

    &:before {
      content: "";
      background: url("/img/nav/applicants/blocks/entrant/entrant-bg.png")
        center bottom/contain no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
