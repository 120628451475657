.college {
  margin-bottom: 12rem;

  &__title {
    text-transform: none;
    font: 600 2.8rem "Inter";
    margin-bottom: 3.4rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 0.1rem solid #cfd3d8;
    margin-bottom: 3rem;
  }

  &__item {
    position: relative;
    padding: 0 0 0 2.4rem;
    width: 50%;
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    display: none;

    &:nth-child(-n + 10) {
      display: flex;
    }

    &:before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.881836' width='12' height='12' rx='2' fill='%23FBB800'/%3E%3C/svg%3E%0A")
        center center/contain no-repeat;
      position: absolute;
      width: 1.2rem;
      top: 0.4rem;
      left: 0;
      right: 0;
      /* bottom: 0; */
      height: 1.2rem;
    }
  }

  &__more {
    display: flex;
    justify-content: center;
    margin-bottom: 10rem;
  }
}
