.admission {
  margin-bottom: 15rem;

  &_main {
    padding-top: 8rem;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;
    margin-bottom: 5rem;
  }

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    letter-spacing: 0.1rem;
  }

  &__link {
    display: flex;
  }

  &__body {
    width: 75%;
  }

  &__switch {
    margin-bottom: 6rem;
  }

  &__switch-list {
    position: relative;
  }

  &__list {
    position: absolute;
    right: 9999rem;
    opacity: 0;
    // overflow: hidden;
    // max-height: 100rem;
  }

  &__edu {
    font-size: 2rem;
    font-weight: 400;
    margin-right: 3.4rem;
    height: 100%;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item {
    border-bottom: 0.1rem solid #cfd3d8;
    display: none;
    padding: 2.4rem 2.2rem 2.2rem 2rem;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: 400;
    align-items: center;
    letter-spacing: -0.01rem;
    line-height: 1.5;
    width: 100%;

    &:nth-child(-n + 6) {
      display: flex;
    }

    &-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 2rem;
      word-break: break-word;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-subitem {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-subtitle {
      margin-bottom: 2.3rem;
    }

    &:first-child {
      font-size: 1.8rem;
      font-weight: 500;
      padding-top: 4.3rem;
      flex-direction: column;
    }

    &:nth-child(2) {
      flex-direction: column;
      padding: 3rem 2rem;
      align-items: stretch;
    }
  }

  &__left {
    display: flex;
    align-items: baseline;
  }

  &__icon {
    position: relative;
    height: 1.7rem;

    &:before {
      content: "";
      position: absolute;
      background: url("data:image/svg+xml,%3Csvg width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 5.95H9.99999C9.77897 5.95 9.56701 5.86045 9.41073 5.70104C9.25445 5.54164 9.16665 5.32543 9.16665 5.1V0H0.831665C0.372499 0 0 0.38675 0 0.8432V16.1568C0.000221252 16.3805 0.0875015 16.595 0.242663 16.7531C0.397826 16.9112 0.608177 17 0.827499 17H14.1725C14.2819 16.9992 14.3901 16.9765 14.4909 16.933C14.5918 16.8896 14.6832 16.8263 14.76 16.7468C14.8369 16.6674 14.8976 16.5732 14.9388 16.4698C14.98 16.3664 15.0007 16.2557 15 16.1441V5.95ZM15 4.25L10.8333 0.00255002V4.25H15Z' fill='%23CFD3D8'/%3E%3C/svg%3E%0A")
        center center / cover no-repeat;
      width: 1.5rem;
      height: 1.7rem;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__desc {
    margin-left: 3.4rem;
    padding-right: 5rem;
  }

  &__button {
    border: 0.1rem solid #cfd3d8;
    border-radius: 0.8rem;
    padding: 0.2rem 0.8rem 0.3rem 0.6rem;
    // position: relative;
    color: #a0a2a6;
    font-size: 1.2rem;
    width: 9.8rem;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &::after {
      content: "";
      -webkit-mask: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.875 6H10.125' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.75 2.625L10.125 6L6.75 9.375' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        center center / contain no-repeat;
      background: #a0a2a6;
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background: #fa9800;
      color: #fff;

      &::after {
        background: #fff;
      }
    }
  }

  &__arrow {
  }
}

.active-adm {
  font-weight: 600;
  color: #212121;
  border-bottom: 0.4rem solid #43c375;
}

.active-adm-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  justify-content: space-between;
  position: static;
  opacity: 1;
  pointer-events: unset;
}
