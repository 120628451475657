.popup {
    padding: 20px 0 !important;
    background: #32A05D !important;
    border-radius: 16px;
    .form {
        border-radius: 0;
        width: auto;
        margin: 0 auto;
        &__title {
            padding: 0;
            font-size: 2.8rem;
            margin-bottom: 2.4rem;
        }
        &__button {
            width: 33rem;
            font-size: 2rem;
            height: 5.5rem;
            align-items: center;
        }
    }
}