.faculties {
  position: relative;
  height: 55rem;
  z-index: 1;

  &::before {
    content: "";
    background: url(/img/nav/cards/entrepreneurship/blocks/faculties/fac-img.png)
      bottom right/41% no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &::after {
    content: "";
    background: #f5f7fa;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    border-radius: 0 0 4rem 4rem;
  }

  &__edu {
    width: 20.9rem;
    padding: 1.2rem 2rem;
    background: #fff;
    border-radius: 0.4rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &__name {
    text-transform: none;
    margin-bottom: 4.2rem;
  }

  &__desc {
    display: flex;
    margin-bottom: 4.6rem;
  }

  &__info {
    display: flex;
  }

  &__line {
    border-left: 0.3rem solid #e91e63;
    margin-right: 1.2rem;
    height: 3.8rem;
    border-radius: 0.4rem;
  }

  &__text {
    font-weight: 400;
    width: 50%;
  }

  &__block {
    margin-bottom: 5.3rem;

    @include phones {
      margin-bottom: 3.5rem;
    }
  }

  &__form-edu {
    text-transform: uppercase;
    margin-bottom: 0.4rem;

    @include phones {
      margin-bottom: 0.8rem;
    }
  }

  &__formats {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 0.8rem;
    transition: 0.5s;

    &:hover {
      color: #604fc9;
      cursor: pointer;
      transition: 0.5s;
    }
  }

  &__form {
    margin-right: 0.8rem;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &__line-grey {
    border-left: 0.2rem solid #cfd3d8;
    margin-right: 1.2rem;
    height: 1.6rem;

    @include phones {
      display: none;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.form {
  &_faculties {
    background: #fff;
    border: 0.1rem solid #cfd3d8;

    &::after {
      filter: opacity(0.5) grayscale(1);
      height: 33.9rem;
    }
  }

  &__title_faculties {
    color: #212121;
  }

  &__block_faculties {
    border: 0.1rem solid #cfd3d8;
    border-radius: 0.6rem;
  }

  &__text-tablets_faculties {
    color: #212121 !important;
  }

  &__text_faculties {
    color: #212121 !important;
  }
}
