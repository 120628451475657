.intelligence {
  padding-top: 8rem;
  background: #f5f7fa;

  &__body {
    margin-bottom: 0;
    padding-bottom: 10rem;
  }

  &__left {
    width: 66%;
  }

  &__right {
    width: 23.7%;
  }

  &__title {
    font: 4.8rem "PF Din Text Comp Pro";
    letter-spacing: 0.1rem;
    margin-bottom: 5rem;
  }

  &__subtitle {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.01rem;
  }

  &__item {
    line-height: 1.5;

    &::before {
      top: 0.5rem;
    }
  }

  &__desc {
    font-size: 1.6rem;
    letter-spacing: -0.01rem;
  }
}
