.about-academy {
  background: #f5f7fa;
  border-radius: 0 0 2rem 2rem;
  padding: 2rem 0 2.5rem 0;
  margin-bottom: 9rem;

  &__container {
    display: flex;
  }

  &__title {
    padding: 4.5rem 0 10rem;
  }

  &__left {
    // width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__right {
    position: relative;
    // width: 50%;
    height: 54rem;
    flex: 1;

    &:before {
      content: "";
      background: url("/img/nav/about/blocks/about-academy/about-academy.png")
        center center / contain no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__desc {
    display: flex;
  }

  &__line {
    border-left: 0.4rem solid #fbb800;
    margin-right: 9rem;
  }

  &__text {
    font-size: 2.8rem;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.6;
  }
}
