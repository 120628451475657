.edu {
  // background: #F5F7FA;
  // width: 100vw;
  // margin-left: calc(-50vw + 50%);
  // padding: 0 14.2rem;
  border-radius: 2rem;
  position: relative;
  z-index: 0;
  height: 90rem;
  margin-top: -13rem;
  padding-top: 25.3rem;
  margin-bottom: 15rem;

  &::before {
    content: "";
    background: url(/img/edu/edu.png) right center/49% no-repeat;
    position: absolute;
    top: 12.3rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &::after {
    content: "";
    background: #f5f7fa;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    border-radius: 2rem;
  }

  &__title {
    font-size: 6.4rem;
    text-transform: uppercase;
    font-family: "PF Din Text Comp Pro Medium";
    margin-bottom: 6rem;
  }

  &__desc {
    display: flex;

    @include tablets {
      width: 94%;
      align-items: flex-end;
    }

    @include phones {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__desc-text {
    position: relative;
    margin-top: 1rem;
  }

  &__desc-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &__title {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    cursor: pointer;
    align-items: center;
    padding-left: 10rem;
    color: #6e7175;
    font-weight: 400;
    font-size: 2rem;
  }

  &__list {
    width: 29%;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    margin-right: 5.5rem;
  }

  &__text {
    width: 37rem;
    min-height: 35rem;
    background: #fff;
    border-radius: 2rem;
    padding: 4.7rem 4rem 4.7rem 4.7rem;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 400;
  }

  &__photo {
    display: none;
  }

  &__img {
    width: 100%;
  }
}


.active-desc {
  position: static;
  opacity: 1;
  pointer-events: unset;
}

.active-edu {
  color: #212121;
  font-size: 2rem;
  font-weight: 600;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border-bottom: 0.4rem solid green;
    left: 0;
    right: 0;
    width: 7.4rem;
    top: 1.3rem;
  }
}
