.acting {
  margin-bottom: 6.6rem;

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    font-weight: 400;
    margin-bottom: 5.3rem;

    @include tablets {
      font-size: 3rem;
    }
  }

  &__employers-title {
    font-size: 2.8rem;
    margin-bottom: 2rem;
  }

  &__employers {
    &-list-title {
      display: flex;
      margin-bottom: 4.3rem;
      scroll-snap-type: x mandatory;
      overflow-x: auto;
      align-items: baseline;
    }

    &-item-title {
      margin-right: 3.2rem;
      font-size: 2rem;
      font-weight: 400;
      cursor: pointer;

      &_active {
        font-weight: 700;
        border-bottom: 0.4rem solid #43c375;
        padding-bottom: 0.6rem;
        color: #212121;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__switch {
    min-height: 28rem;
  }

  &__switch-list {
    position: relative;
  }

  &__switch-item {
    margin-bottom: 0.8rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &__employers-item {
    width: 17.6rem;
    height: 12rem;
    border: 0.1rem solid #cfd3d8;
    border-radius: 1rem;
    display: none;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 2rem;
    &:nth-child(-n + 12) {
      display: flex;
    }
  }

  &__employers-img {
    object-fit: contain;
  }

  &_employers-img {
    object-fit: contain;
  }

  &__more {
    display: flex;
    justify-content: center;
  }
}

.active-acting-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: static;
  opacity: 1;
  pointer-events: unset;
}
