.links {
  margin-bottom: 120px;
  &-phone {
    position: absolute;
    bottom: 0;
    left: 30px;
    max-width: 35%;
    width: 100%;
  }
  &__box {
    position: relative;
    border-radius: 13px;
    background: #F5F7FA;
    padding: 0 70px;
    &-info {
      max-width: 60%;
      width: 100%;
      margin: 0 0 0 auto;
      padding: 40px 0;
      h3 {
        color:#212121;
        font-family: "PF Din Text Comp Pro";
        font-size: 50px;
        font-weight: 500;
        line-height: 110%;
        text-transform: uppercase;
        margin-bottom: 20px;
        span {
          color: #32A05D;
        }
      }
      p {
        color:#212121;
        font-size: 25px;
        line-height: 140%; 
        margin-bottom: 40px;
      }
      div {
        display: flex;
        align-items: center;
        a {
          max-width: 165px;
          width: 100%;
          margin-right: 20px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
