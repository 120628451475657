.vacancy {
  padding-bottom: 12rem;

  &__title {
    font: 4.8rem "PF Din Text Comp Pro";
    margin-bottom: 5rem;
    letter-spacing: 0.1rem;
    line-height: 1;

    SPAN {color: $green;}
  }

  &__tabsblock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    gap: 3rem 0;

    &-title {
      padding-left: 10rem;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 121%;
      color: #6e7175; 
      cursor: pointer;

      &.current {
          position: relative;
          font-weight: 600;
          color: #000;

          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: '';
            display: block;
            width: 7.4rem;
            height: .4rem;
            background: $green;
        }
      }
    }
  }

  &__tabsbox {
    display: none;
    justify-content: space-between;

    &.visible {
        display: flex;
    }

    &-photo {

      IMG {
        border-radius: 2rem;
      }
    }
  }

  &__info {
    padding: 2.5rem;
    border-radius: 1rem;
    background: $green;
    font-size: 2.4rem;
    line-height: 121%;
    color: #fff;
    text-align: center;
    margin: 0 10rem;
  }

}