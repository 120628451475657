.menu-basic {
    
  &__item {
    font-weight: 400;
    border-bottom: 0.1rem solid #a0a8b1;
    padding: 1.6rem 4rem 1.6rem 0;
    padding-right: 0;
    line-height: 1.46;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    
    &_active {
      color: #604fc9;
      border-bottom: 0.1rem solid #604fc9;
    }

    &:hover {
      color: #604fc9;
      border-bottom: 0.1rem solid #604fc9;
    }
  }
}
