.faculties-design {
  position: relative;
  height: 55rem;
  z-index: 1;

  &::before {
    content: "";
    background: url(/img/nav/cards/design/blocks/faculties/fac-img.png) bottom
      right/41% no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &__info {
    display: flex;
    flex: 0 0 18%;
    margin-right: 5rem;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    width: auto;
  }
}
