.directions {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16rem;

  &__head {
    margin-bottom: 5rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &__more {
    margin-bottom: 7rem;
    display: flex;
    justify-content: center;
  }

  &__title {
    font-family: "PF Din Text Comp Pro Medium";
    text-transform: uppercase;
    font-size: 6.4rem;
    font-weight: 500;
    margin-bottom: 3.5rem;
  }

  &__link {
    display: flex;
  }

  &__edu {
    margin-right: 3.4rem;
    font-size: 2rem;
    color: #000000;
    font-weight: 400;

    &:last-child {
      margin: 0;
    }
  }

  &__item {
    width: 32%;
    height: 17rem;
    border-radius: 2rem;
    // margin-right: 1.9rem;
    margin-bottom: 2rem;
    display: none;
    justify-content: space-between;
    padding: 2.1rem;
    background: right bottom / auto no-repeat;
    position: relative;

    &:nth-child(-n + 6) {
      display: flex;
    }

    

    &:nth-child(1) {
      background-color: #ffe8a7;
    }

    &:nth-child(2) {
      background-color: #dfe7f1;
    }

    &:nth-child(3) {
      background-color: #d7d5cf;
    }

    &:nth-child(4) {
      background-color: #d7d5cf;
    }

    &:nth-child(5) {
      background-color: #c7e8d4;
    }

    &:nth-child(6) {
      background-color: #d8e7ff;
    }

    &:nth-child(7) {
      background-color: #d7d5cf;
    }

    &:nth-child(8) {
      background-color: #c7e8d4;
    }

    &:nth-child(9) {
      background-color: #dfe7f1;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20rem;
    z-index: 100;
  }

  &__spec {
    font-size: 2rem;
    line-height: 1.4;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.active-dir {
  border-bottom: 0.4rem solid #43c375;
  font-weight: 600;

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    justify-content: space-between;
    position: static;
    opacity: 1;
    pointer-events: unset;
  }
}
