.neuronet {
    position: fixed; 
    z-index: 101;
    width: 100%;
    
    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 99.4rem;
        padding: 17.8rem 4rem 8.2rem 4rem;
        border-radius: 4rem;
        background: #32a05d;
        box-shadow: 0 0.3rem 0 0 #000;
        text-align: center;
        margin: 0 auto;

        &:before {
            position: absolute;
            top: -8.2rem;
            left: 50%;
            transform: translate(-50%, 0);
            content: '';
            display: block;
            width: 21.9rem;
            height: 24.2rem;
            background: url(../img/neuronet/figure.png) no-repeat 50% 50% / 100% 100%;
        }
    }

    &__close {
        position: absolute;
        top: 3.4rem;
        right: 3.4rem;
        content: '';
        display: block;
        width: 4rem;
        height: 4rem;
        background: url(../img/neuronet/close.svg) no-repeat 50% 50% / 100% 100%;
        cursor: pointer;
    }

    &__title {
        padding: 0 1rem;
        border-radius: 1.3rem;
        background: #fdce4c;
        font: 500 6.4rem "PF Din Text Comp Pro Medium", sans-serif;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    &__text {
        font: 500 6.4rem "PF Din Text Comp Pro Medium", sans-serif;
        line-height: 100%;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 3.5rem;
    }

    &__button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 19.5rem;
        height: 4.8rem;
        padding: 0 1.9rem;
        border: 0;
        border-radius: 2rem;
        background: #fdce4c;
        box-shadow: 0 0.3rem 0 0 #000;
        font-weight: 600;
        font-size: 1.6rem;
        color: #000;
        text-align: center;
        cursor: pointer;
        margin-right: 4.8rem;
    
        &:after {
            position: absolute;
            top: 0;
            bottom: -0.1rem;
            right: -4.8rem;
            content: '';
            display: block;
            width: 4.8rem;
            border-radius: 2rem;
            background: #fdce4c url(../img/neuronet/pointer_black.svg) no-repeat 50% 50% / 0.8rem 1.3rem;
            box-shadow: 0 0.3rem 0 0 #000;
        }
    }
}

.hidelayout {
    position: fixed; 
    z-index: 100; 
    top: 0; 
    bottom: 0; 
    left: 0;
    right: 0;
    display: none; 
    width: 100%; 
    height: 100%; 
    background: rgba(67, 67, 67, .9);
}