.menu {
  &__fix {
    &_intelligence {
      height: auto;
      width: 28.3rem;
    }
  }

  &__list_intelligence {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scroll-snap-type: y;
    height: 54rem;
  }
}
