.news {
  margin-bottom: 10.7rem;

  &__title {
    text-transform: uppercase;
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    line-height: 1.16;
    letter-spacing: 0.1rem;
    margin-bottom: 3rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8.3rem;
  }

  &__item {
    background: center top / contain no-repeat;
    width: calc(97% / 3);
    // height: 35rem; ???
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 2rem;

    // &:nth-child(1) {
    //     background-image: url("/img/news/news-01.png");
    //     margin-bottom: 2rem;
    // }

    // &:nth-child(2) {
    //     background-image: url("/img/news/news-02.png");
    //     margin-bottom: 2rem;
    // }

    // &:nth-child(3) {
    //     background-image: url("/img/news/news-03.png");
    //     margin-bottom: 2rem;
    // }

    // &:nth-child(4) {
    //     background-image: url("/img/news/news-04.png");
    // }

    // &:nth-child(5) {
    //     background-image: url("/img/news/news-05.png");
    // }

    // &:nth-child(6) {
    //     background-image: url("/img/news/news-05.png");
    // }
  }

  &__img {
    overflow: hidden;
    height: 17.2rem;
  }

  &__icon {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }

  &__info {
    border: 0.1rem solid #cfd3d8;
    border-radius: 0 0 2rem 2rem;
    padding: 2rem 1.6rem 6rem 2rem;
  }

  &__date {
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
    font-weight: 400;
  }

  &__desc {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.37;
  }

  &__more {
    display: flex;
    margin-bottom: 7rem;
    justify-content: center;
  }
}
