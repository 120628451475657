.employment {
  //background: #f5f7fa;
  padding-bottom: 6rem;

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 66%;
  }

  &__title {
    margin-bottom: 3.2rem;
  }

  &__subtitle {
    font-size: 2.8rem;
    margin-bottom: 2.4rem;
  }

  &__sub-desc {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
  }

  &__desc {
    display: flex;
  }

  &__line {
    border-left: 0.4rem solid #e91e63;
    margin-right: 9.2rem;
    border-radius: 1rem;
  }

  &__text-up {
    font-size: 2.8rem;
    font-weight: 400;
    margin-bottom: 4.8rem;
    line-height: 1.6;
  }

  &__text-down {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
  }

  &__right {
    width: 25%;
  }
}
