.information {
  background: #f5f7fa;
  padding-bottom: 12rem;

  &__body {
    width: 66%;
  }

  &__title {
    font: 4.8rem "PF Din Text Comp Pro";
    margin-bottom: 5rem;
    letter-spacing: 0.1rem;
    line-height: 1;
  }

  &__subtitle-mobile {
    display: none;
  }

  &__switch {
    margin-bottom: 6rem;
  }

  &__list-link {
    display: flex;
    margin-bottom: 2.4rem;
  }

  &__item-link {
    font-size: 2rem;
    font-weight: 400;
    margin-right: 3.4rem;
    line-height: 1.6;
    white-space: nowrap;

    &_active {
      font-weight: 600;
      border-bottom: 0.2rem solid #43c375;
      line-height: 1.46;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__desc {
    margin-bottom: 4rem;
    position: relative;
  }

  &__desc-switch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &__switch-block {
    position: relative;
  }

  &__switch-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &__desc-text-bold {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  &__desc-text {
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.5;
  }

  &__item {
    border: 0.1rem solid #cfd3d8;
    border-radius: 2rem;
    padding: 3.3rem 3.6rem 4.3rem 3.5rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__step {
    padding: 0.9rem 1.2rem;
    background: #c9edd5;
    border-radius: 1rem;
    color: #1c6e3d;
    text-transform: uppercase;
    line-height: 1;
    white-space: nowrap;
    margin-bottom: 2.9rem;
    display: inline-block;

    &_numbers {
      margin-bottom: 3.2rem;
    }
  }

  &__step-title {
    font-size: 2rem;
    margin-bottom: 1.2rem;

    &_three {
      margin-bottom: 2.8rem;
    }
  }

  &__step-desc {
    margin-bottom: 3rem;
    display: flex;
    font-size: 1.6rem;
    font-weight: 400;
    margin-left: 0.6rem;
    line-height: 1.5;
    letter-spacing: -0.01rem;

    &::before {
      content: "\2022";
      margin-right: 0.8rem;
    }
  }

  &__step-subtitle {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    letter-spacing: -0.01rem;
  }

  &__step-sublist {
    &_bottom {
      margin-bottom: 2.4rem;
    }
  }

  &__step-subitem {
    font-size: 1.6rem;
    font-weight: 400;
    display: flex;
    margin-left: 0.6rem;
    margin-bottom: 1rem;
    letter-spacing: -0.01rem;
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "\2022";
      margin-right: 0.8rem;
    }

    &_two {
      line-height: 1.48;

      &:nth-child(1) {
        &::before {
          content: "1.";
        }
      }
      &:nth-child(2) {
        &::before {
          content: "2.";
        }
      }
      &:nth-child(3) {
        &::before {
          content: "3.";
        }
      }
    }

    &_three {
      line-height: 1.47;
    }

    &_four {
      margin-left: 0.4rem;

      &:nth-child(1) {
        &::before {
          content: "1.";
        }
      }
      &:nth-child(2) {
        &::before {
          content: "2.";
        }
      }
      &:nth-child(3) {
        &::before {
          content: "3.";
        }
      }
    }

    &_five {
      line-height: 1.47;
    }
  }

  &__text-hell {
    font-weight: 400;
    letter-spacing: -0.01rem;
    line-height: 1.5;

    &_four {
      margin-bottom: 2.4rem;
    }
  }

  &__text-four {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 2.4rem;
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__step-sublist_numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__numbers {
    display: inline-flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    margin-bottom: 3.8rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(4) {
      margin-bottom: 0;
    }

    &-top {
      font: 700 5.4rem "PF Din Text Comp Pro Medium";
      color: #fa9800;
      margin-bottom: 1.5rem;

      &-mobile {
        &-two {
          letter-spacing: -0.3rem;
        }
      }
    }

    &-bottom {
      text-align: center;
      font-size: 1.2rem;
    }
  }

  &__question-title {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__question-subtitle {
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.information__switch-active {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: static;
  opacity: 1;
  pointer-events: unset;
}
