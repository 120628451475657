.form-partners {
  max-width: 115.6rem;
  width: 100%;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  padding: 5rem 13.7rem 3rem 13.7rem;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  min-height: 51rem;
  background: url("/img/nav/partners/blocks/form-partners/form-heptagon.png")
    #fa9800 center / 90% no-repeat;

  &__title {
    padding: 0;
    flex: 1 0 20%;
  }

  &__row.form__row {
    flex-direction: column;
    align-items: stretch;
  }

  &__group {
    display: flex;
    margin-bottom: 2rem;
   
  }

  &__line {
    border: 0.1rem solid #fdce4c;
    margin-bottom: 2rem;
  }

  &__block {
    width: 100%;
    margin-right: 2rem;

    &-text {
      width: 100%;
      margin-bottom: 3.2rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__input-big {
    resize: none;
  }
}
