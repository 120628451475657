.reception {
  margin-bottom: 15rem;

  &__body {
    padding: 6rem 12rem 6rem 10rem;
    border: 0.1rem solid #cfd3d8;
    border-radius: 2rem;
    width: 75%;
  }

  &__title {
    font-size: 2.8rem;
    margin-bottom: 4.5rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    white-space: nowrap;
  }

  &__address {
    margin-bottom: 5.3rem;

    &-title {
      color: #a0a2a6;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  &__street {
    font-size: 1.6rem;
    // width: 55%;
    line-height: 2;
  }

  &__schedule {
    &-title {
      color: #a0a2a6;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  &__time {
    font-size: 1.6rem;
    line-height: 2;
  }

  &__tel {
    margin-bottom: 4.5rem;

    &-title {
      color: #a0a2a6;
      text-transform: uppercase;
      margin-bottom: 1rem;
      white-space: nowrap;
    }

    &-list {
      display: flex;
      flex-direction: column;
      line-height: 2.2;
    }
  }

  &__phone {
    font-size: 1.6rem;
  }

  &__mail {
    &-list {
      display: flex;
      flex-direction: column;
      line-height: 2;
    }

    &-title {
      color: #a0a2a6;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  &__adress-mail {
    font-size: 1.6rem;
  }
}
