.button {
  background: #fdce4c;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 1;
  color: $dark;

  @include tablets {
    width: 16rem;
    height: 3.5rem;
    font-size: 1.2rem;
  }

  &_form {
    width: 33rem;
    height: 5.5rem;
    font-size: 1.8rem;
    align-items: center;

    @include tablets {
      width: 100%;
      height: 4.8rem;
      margin-bottom: 1.2rem;
    }

    @include phones {
      font-size: 2rem;
      height: 5.8rem;
      margin-bottom: 1.4rem;
    }
  }

  &_more {
    width: 18.7rem;
    background-color: #fff;
    border: 0.1rem solid #fdce4c;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;

    @include tablets {
      height: 4.2rem;
      font-size: 1.6rem;
    }

    @include phones {
      width: 100%;
      align-items: center;
    }

    &::before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.19995 9.65723H15.8' stroke='black' stroke-linecap='round'/%3E%3Cpath d='M9.5 3.35718L9.5 15.9572' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E")
        center center / 2rem no-repeat;
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1.2rem;

      // @include phones {
      //     width: 1.8rem;
      //     height: 100%;
      //     position: absolute;
      //     top: 0;
      //     left: 8.5rem;
      //     bottom: 0;
      //     right: 0;
      // }
    }
  }

  &_form-partners {
    width: 35rem;

    @include tablets {
      margin-bottom: 1.2rem;
      width: 100%;
    }
  }

  &_acting {
    width: 30rem;
  }

  &_admission {
    width: 35rem;
    font-size: 1.4rem;
    line-height: 1;

    @include phones {
      width: 100%;
    }
  }

  &_program-edu {
      
    @include tablets {
      width: 100%;
    }
  }

  &_council {
    display: none;

    @include phones {
      display: flex;
      width: 95%;
      font-size: 1.6rem;
    }
  }

  &_library {
    background: #fdce4c;
    width: auto;
    height: auto;
    border-radius: 0.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    justify-content: space-around;
    line-height: 1;
    padding: 1.6rem 6.35rem;

    @include phones {
        padding: .9rem 3.4rem;
        font-size: 1.6rem;
    }
  }

  &_pay_link {
    margin-right: 10px;
    background: #32A05D;
    color: #fff;
  }

}
