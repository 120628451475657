.lang-foreigner-desc {
    margin-bottom: 12rem;

    &__block {
        margin-bottom: 7rem;
    }

    &__top {
        color: #32a05d;
    }

    &__info {
        font-size: 4rem;
        text-align: center;
        margin-bottom: 5rem;

        &-color {
            color: #32a05d;
        }
    }
}