.lang-advantages {
  margin-bottom: 12rem;
  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    letter-spacing: 0.1rem;
    margin-bottom: 4rem;
  }

  &__item {
    margin-left: 2rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
    &-title {
      font-size: 1.6rem;
      margin-bottom: 1.2rem;
      position: relative;

      &::before {
        content: "";
        background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' fill='%23C4C4C4'/%3E%3C/svg%3E%0A")
          center center/0.6rem no-repeat;
        position: absolute;
        width: 0.6rem;
        top: 0;
        bottom: 0;
        left: -2rem;
      }
    }

    &-desc {
      font-size: 1.6rem;
      font-weight: 400;

      &-item {
          position: relative;
        &:before {
          content: "—";
          position: absolute;
          left: -2rem;
        }
      }
    }
  }
}
