$desktop:       1200px;
$tablets:       768px;
$phones:        468px;

$dark:          #212121;
$green:         #32A05D;
$greenLogo:     #1C6F3D;
$yellowLogo:    #FBB800;

@mixin desktop {
  @media screen and (max-width: $desktop) {
    @content;
  }
}


@mixin tablets {
  @media screen and (max-width: $tablets) {
      @content;

  }
}

@mixin phones {
  @media screen and (max-width: $phones) {
      @content;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus, textarea:focus, input:focus {
          font-size: 16px;
      }
  }

  
// @include hidden;
// @mixin hidden {
// 	position: absolute;
// 	left: -9999px;
// 	opacity: 0;
// }

// // @include visible;
// @mixin visible {
// 	position: static;
// 	opacity: 1;
// }