.timetable {
  background: #f5f7fa;
  padding-bottom: 12rem;

  &__body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5.4rem;
  }

  &__left {
    width: 66%;
  }

  &__right {
    width: 25%;
  }

  &__top {
    margin-bottom: 3rem;
  }

  &__title {
    font: 4.8rem "PF Din Text Comp Pro";
    letter-spacing: 0.1rem;
  }

  &__group-list {
    width: 100%;
      max-width: 28rem;
      padding: 0 2rem 0 0;
      border: 0;
      -webkit-appearance: none;
      outline: none;
      background: transparent url(../img/nav/students/timetable/blocks/timetable/select.png) no-repeat 100% 50%;
      font: 4.8rem "PF Din Text Comp Pro Medium";
      letter-spacing: 0.1rem;
      color: #32a05d;

      &:focus {
        font-size: 4.8rem;
      }

      option {
        font-family: "arial";
        font-size: 2rem ;
      }
  }

  &__list {
    display: inline-flex;
    align-items: center;
    border: 0.1rem solid #cbd5e0;
    border-radius: 0.8rem;
    padding: 0.6rem 0.8rem;
    margin-bottom: 0.5rem;
  }

  &__item {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__subitem {
    padding: 0.4rem 1rem;
    cursor: pointer;
    font-weight: 400;
    color: #4a5568;

    &_active {
      background: #32a05d;
      border-radius: 0.4rem;
      color: #fff;
    }
  }

  &__download {
    display: flex;
    margin-bottom: 1.2rem;
  }

  &__btn {
    display: flex;
    border: 0.1rem solid #cbd5e0;
    border-radius: 0.4rem;
    padding: 0.8rem 1rem;
    align-items: baseline;
    margin-right: 0.8rem;
    line-height: 1;
  }

  &__download-text {
    font-weight: 500;
    margin-right: 1.2rem;
  }

  &__download-btn {
    border: 0.1rem solid #cfd3d8;
    border-radius: 0.8rem;
    padding: 0.2rem 0.8rem;
    position: relative;
    font-size: 1.2rem;
    width: 9.8rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }

  &__download-print {
    border: 0.1rem solid #cbd5e0;
    border-radius: 0.4rem;
    display: flex;
    padding: 0.8rem 1rem;
    align-items: center;

    &-vis {
      background: url("/img/nav/students/timetable/blocks/timetable/vis.svg")
        center center / contain no-repeat;
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1.2rem;
    }

    &-text {
      font-weight: 500;
      line-height: 1;
    }
  }

  &__download-btn-arrow {
    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.875 6H10.125' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.75 2.625L10.125 6L6.75 9.375' stroke='%236E7175' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      center center/contain no-repeat;
    width: 1.2rem;
    height: 1.2rem;
    transform: rotate(90deg);
  }

  &__scroll {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    width: 100%;
  }
  &__classes {
    border-collapse: collapse;
    border-radius: 1rem;
    overflow: hidden;
    // table-layout: fixed;

    &-head {
      text-transform: uppercase;
      background: #e4e7eb;
      white-space: nowrap;
    }

    &-item {
      padding: 1.6rem 2rem 1.6rem 2rem;
      text-align: inherit;
      font-size: 1rem;
      color: #8492a6;
    }

    &-body {
      background: #fff;
      border-bottom: 0.1rem solid #edf2f7;

      &:last-child {
        border-bottom: none;
      }
    }

    &-subitem {
      padding: 1.6rem 0 1.6rem 2rem;
      font-weight: 400;
      color: #8492a6;

      &:last-child {
        padding-right: 2rem;
      }
    }
  }

  &__occupations {
    border: 0.1rem solid #CFD3D8;
    border-radius: 2rem;
    padding: 4rem 5rem;
    width: 100%;

    &-wrap {
      // display: flex;

      &_mobile {
        display: none;
      }
    }

    &-list {
      // width: 20%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-title {
      color: #8492a6;
      margin-bottom: 2.4rem;
      font-size: 1.2rem;
    }

    &-item {
      margin-bottom: 2rem;
      width: 10rem;
      margin-right: 3rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &-name {
      font-weight: 700;
      font-size: 1.6rem;
      margin-bottom: 0.2rem;
      line-height: 1.25;
    }

    &-desc {
      color: #425466;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.33;
    }
  }
}
