.disciplines {
  padding-top: 25rem;
  margin-bottom: 12rem;

  &__desc {
    display: flex;
    align-items: center;
    margin-bottom: 12rem;
  }

  &__text {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 1.7;
    width: 93rem;
    border-left: 0.3rem solid #e91e63;
    padding-left: 9.2rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12rem;
  }

  &__title {
    font-size: 4.8rem;
    font-family: "PF Din Text Comp Pro";
    width: 30%;
    letter-spacing: 0.1rem;
    line-height: 1.16;
  }

  &__wrapper {
    width: 76.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__about {
    background: #f5f7fa;
    border-radius: 2rem;
    width: 100%;
    transition: 0.4s;
    margin-bottom: 2rem;
  }

  &__list {
    padding: 0 4rem 0 4rem;
    overflow: hidden;
    max-height: 0;
    transition: 0.5s;
  }

  &__item {
    font-weight: 400;
    margin-bottom: 1.6rem;
    transition: 0.5s;
    align-items: center;
    position: relative;
    padding: 0 0 0 1.5rem;
    font-size: 1.6rem;
    line-height: 1.12;

    &:last-child {
      margin-bottom: 3rem;
    }

    &::before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' fill='%23C4C4C4'/%3E%3C/svg%3E%0A")
        center center / 0.6rem no-repeat;
      position: absolute;
      width: 0.6rem;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 2.8rem;
    padding: 4rem;
    cursor: pointer;
  }

  &__top-title {
    font-size: 2rem;
    width: 90%;
  }

  &__btn {
    width: 3.2rem;
    height: 3.2rem;
    background: #fff;
    border-radius: 50%;
    position: relative;
    flex: 0 0 3.2rem;

    &:before {
      content: "";
      position: absolute;
      /* top: 0; */
      left: 0;
      right: 0;
      /* bottom: 0; */
      width: 2rem;
      /* height: 2rem; */
      border-bottom: 0.1rem solid #000000;
      margin: 0 auto;
      border-radius: 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      /* top: 0; */
      left: 0;
      right: 0;
      /* bottom: 0; */
      width: 2rem;
      /* height: 2rem; */
      border-top: 0.1rem solid #000000;
      margin: 0 auto;
      border-radius: 1rem;
      transform: rotate(90deg);
      transition: 0.2s;
      opacity: 1;
    }

    // &.rotate {
    //   &:after {
    //     transform: rotate(0deg);
    //   }
    // }
  }
}

.disciplines__list.visible {
  transition: 0.5s;
  height: 0;
}

.disciplines__acc-active .disciplines__btn {
  &:after {
    transform: rotate(0deg);
  }
}