.faculties-municipal-government {
  position: relative;
  height: 55rem;
  z-index: 1;

  &::before {
    content: "";
    background: url(/img/nav/cards/municipal-government/blocks/faculties/fac-img.png) bottom
      right/41% no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: -6rem;
    left: 0;
    z-index: -1;
  }
}
