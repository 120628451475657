.agents {
  position: relative;
  margin-bottom: 9rem;

  &::before {
    content: "";
    background: url(/img/nav/partners/blocks/agents/bg.png) right top/40%
      no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  &:after {
    content: "";
    background: #f5f7fa;
    border-radius: 3rem;
    position: absolute;
    height: 85%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    z-index: -2;
  }

  &__title {
    font-size: 4.8rem;
    font-family: "PF Din Text Comp Pro";
    padding-top: 7.5rem;
    margin-bottom: 4rem;
  }

  &__text {
    margin-bottom: 7rem;
  }

  &__desc {
    width: 50%;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 1.2rem;

    &:last-child {
      margin-bottom: 0;

      @include tablets {
        width: 66%;
        white-space: break-spaces;
      }

      @include phones {
        width: 100%;
      }
    }
  }

  &__benefits-title {
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: 27rem;
    min-height: 14rem;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
  }

  &__heptagon {
      height: 5.5rem;
  }

  &__info {
    font-size: 1.8rem;
    font-weight: 400;
  }

  &__line {
    border-bottom: 0.2rem solid #fdce4c;
    width: 100%;
  }
}
