.about {
  background: #fee9b1;
  border-radius: 2rem;
  min-height: 63.6rem;
  position: relative;
  z-index: 1;
  margin-bottom: 8rem;

  &::before {
    content: "";
    background: url(/img/about/about.svg) right center/35% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: -12rem;
    bottom: 0;
    z-index: -1;
  }

  &__title {
    font-family: "PF Din Text Comp Pro Medium";
    font-size: 6.4rem;
    padding: 6rem 0 6rem;
  }

  &__info {
    display: flex;
    margin-bottom: 7rem;
    flex-wrap: wrap;
  }

  &__desc {
    margin-right: 4.5rem;
    display: flex;
    align-items: center;
    width: 25rem;
  }

  &__num {
    font-family: "PF Din Text Comp Pro";
    font-size: 6.4rem;
    color: #fbb800;
    margin-right: 2rem;
    line-height: 1;
  }

  &__text {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    // width: 36%;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    width: 17.6rem;
    height: 20rem;
    margin-bottom: 2rem;
    padding: 2.6rem 1rem 2.6rem 1.8rem;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
  }
}
