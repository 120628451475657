.dormitory {
  margin-bottom: 12rem;

  &__desc {
    width: 75%;
    margin-bottom: 12rem;
  }

  &__title {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    letter-spacing: 0.1rem;
    margin-bottom: 4rem;
  }

  &__subtitle {
    font-size: 2rem;
    margin-bottom: 2.4rem;
    width: 65%;
  }

  &__list {
    display: flex;
    margin-bottom: 2.4rem;
  }

  &__item {
    background: #f5f7fa;
    padding: 4.1rem 2.6rem 4.4rem 2.6rem;
    border-radius: 3rem;
    margin-right: 2rem;
    width: 27.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 3rem;
    text-align: center;
    white-space: pre;
  }

  &__icon {
    display: flex;
    justify-content: center;
    height: 23rem;
  }

  &__sublist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__subitem {
    display: flex;
    align-items: center;
    width: 49%;
    border: 0.1rem solid #cfd3d8;
    border-radius: 2rem;
    padding: 1.4rem 1.6rem 1.6rem 2.2rem;
    margin-bottom: 1.6rem;

    &:nth-child(5) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subitem-icon {
    width: 4rem;
    height: 4rem;
    background: #80d69f;
    border-radius: 50%;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 4rem;
  }

  &__subitem-text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
  }
}
