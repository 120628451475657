.paid {
  background: #f5f7fa;
  &__body {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12rem;
  }

  &__left {
    width: 65%;
  }

  &__title {
    font: 4.8rem "PF Din Text Comp Pro";
    margin-bottom: 3.2rem;
  }

  &__subtitle {
    font-size: 1.8rem;
    margin-bottom: 2.8rem;
  }

  &__item {
    position: relative;
    margin-left: 0.9rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background: url("data:image/svg+xml,%3Csvg width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 5.95H9.99999C9.77897 5.95 9.56701 5.86045 9.41073 5.70104C9.25445 5.54164 9.16665 5.32543 9.16665 5.1V0H0.831665C0.372499 0 0 0.38675 0 0.8432V16.1568C0.000221252 16.3805 0.0875015 16.595 0.242663 16.7531C0.397826 16.9112 0.608177 17 0.827499 17H14.1725C14.2819 16.9992 14.3901 16.9765 14.4909 16.933C14.5918 16.8896 14.6832 16.8263 14.76 16.7468C14.8369 16.6674 14.8976 16.5732 14.9388 16.4698C14.98 16.3664 15.0007 16.2557 15 16.1441V5.95ZM15 4.25L10.8333 0.00255002V4.25H15Z' fill='%23CFD3D8'/%3E%3C/svg%3E%0A")
        center center/cover no-repeat;
      width: 1.4rem;
      height: 1.6rem;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__desc {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 0.8rem;
    margin-left: 3.1rem;
  }

  &__btn {
    border: 0.1rem solid #cfd3d8;
    border-radius: 0.8rem;
    padding: 0.2rem 0.6rem 0.2rem 2.4rem;
    position: relative;
    font-size: 1.2rem;
    font-weight: 500;
    display: inline-block;
    justify-content: space-between;
    margin-left: 3.1rem;
    cursor: pointer;
    transition: 0.2s;
    letter-spacing: -0.01rem;

    &::before {
      content: "";
      position: absolute;
      -webkit-mask: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75 8.33301V5.33301H8.25V8.33301H6L9 11.333L12 8.33301H9.75Z' fill='%23F96605'/%3E%3Crect x='4' y='13' width='10' height='1' fill='%23F96605'/%3E%3C/svg%3E%0A")
        center center / cover no-repeat;
      background: #f96605;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0.4rem;
      width: 1.8rem;
    }

    &:hover {
      background: #fa9800;
      color: #fff;

      &::before {
        background: #fff;
      }
    }
  }

  &__right {
    width: 25%;
  }
}
