.exams {
  margin-bottom: 12rem;

  &__tile {
    font-family: "PF Din Text Comp Pro";
    font-size: 4.8rem;
    margin-bottom: 4rem;
  }

  &__list {
    display: flex;
  }

  &__item {
    width: 100%;
    min-height: 12rem;
    background: #f5f7fa;
    border-radius: 2rem;
    padding: 2.1rem 7.4rem 2.1rem 2.5rem;
    margin-right: 2rem;
    transition: .3s;

    &:hover {
      transition: .3s;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
      transform: translateY(-1rem);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__profile {
    margin-bottom: 1.2rem;
    font-size: 2rem;
  }

  &__profile-subject {
    color: #6e7175;
    font-size: 1.6rem;
  }
}
