.lang-edu-foreigner {
    margin-bottom: 12rem;
    background: #f5f7fa;
    border-radius: 0 0 2rem 2rem;
    padding: 6rem 0 6rem 0;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__list {
        display: flex;
        justify-content: flex-end;
    }

    &__item {
        margin-right: 2rem;
        text-transform: uppercase;

        &:last-child {
            margin-right: 0;
        }

    }
    
    &__link {

        &_active {
            color: #1c6e3d;
            font-weight: 700;
        }
        &:hover {
            color: #1c6e3d;
            font-weight: 700;
        }
    }
    &__title {
        margin-bottom: 5rem;
    }

    &__desc {
        font-size: 2rem;
        width: 60%;
    }
}
