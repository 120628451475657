.menu-mob {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: #313131;
  color: #fff;
  position: fixed;
  top: 0;
  display: none;
  z-index: 100;
  padding-top: 7rem;

  &__info {
    font-size: 1.6rem;
    margin-top: 2rem;
    text-align: center;
  }

  &__list {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-bottom: 2rem;
    font-size: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__close {
    position: fixed;
    top: 1.2rem;
    right: 1.8rem;
    width: 2.4rem;
    height: 2.4rem;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 2.4rem;
      height: 0.4rem;
      background: #fff;
      border-radius: 0.5rem;
      left: 0;
      margin: auto;
      bottom: 0;
      transform: rotate(45deg);
      cursor: pointer;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 2.4rem;
      height: 0.4rem;
      background: #fff;
      border-radius: 0.5rem;
      left: 0;
      margin: auto;
      bottom: 0;
      transform: rotate(-45deg);
      cursor: pointer;
    }
  }
  &__socials {
    margin-top: 4rem;
    &-box {
      display: flex; 
      justify-content: center;
    }
    &-name {
      font-size: 1.8rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    a {
      width: 13.4rem;
      height: 4.2rem;
      margin: 0 0.5rem;
    }
  }
  .button_pay_link {
    font-size: 2rem;
    width: auto;
    height: 5rem;
    margin: 2rem 0 0 0;
  }
}