html {
  font-size: 10px;
  height: 100%;
}

body {
  font: 600 1.4rem "Inter", sans-serif;
  background-color: #fff;
  color: $dark;
  line-height: 1.42;
  height: 100%;
  // overflow-x: hidden

  
  &.scroll-lock {
    overflow: hidden;
  }

  &.mosap-team-page {
    background: #F5F7FA;
  }


  &.pay-page {
    background: #F5F7FA;
  }

}

.wrapper {
  overflow: hidden;
  position: relative;
  // display: flex;
  // flex-direction: column;
  // height: 100%;

}

// .content {
//   flex: 1 0 auto;
// }

// .foot {
//   flex: 0 0 auto;
// }

.container {
  max-width: 115.5rem;
  margin: 0 auto;
  width: 92%;
}


.green {
  color: #32a05d;
}

// Запрет масштабирования по двойному клику на сенсорных экранах
a,
area,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}

* {
  list-style: none;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font: 500 6.4rem "PF Din Text Comp Pro Medium", sans-serif;
  text-transform: uppercase;
}

ul,
li {
  margin: 0;
}

button {
  background: transparent;
  -webkit-appearance: none;
}
